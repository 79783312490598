<template>
	<router-view
		:horse_id="horse_id"
	></router-view>
</template>

<script>
export default {
	name: 'Stallion',
	props: ['horse_id']
}
</script>
